import { React, useState, useEffect, Button, Modal, PuffLoader, override, ToastContainer, toast } from "../../../services/common"
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import Login from "../../Auth/Login";
import Signup from "../../Auth/Signup";
import PhoneAuth from "../../Auth/MobileLogin";
import ForgotPassword from "../../Auth/ForgotPassword";
import "./Header.css";
import logologin from "../../../assets/images/logo-login.svg";
import logo from "../../../assets/images/logo.svg"
import phoneicon from "../../../assets/images/phone-icon.svg";
import google from "../../../assets/images/Google.svg";
import hamburger from "../../../assets/images/hamburger.svg"

const Header = ({home}) => {
    const initialState = {
        login: false,
        signup: false,
        otpLogin: false,
        forgotPass: false
    };
    const [loading, setLoading] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const { getClaims, googleSignIn, logout, currentUser } = useAuth();
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };




    const showView = (view) => {
        setState(() => ({
            ...initialState,
            [view]: true
        }));
    };

    const googleLogin = () => {
        googleSignIn().then(() => {
            fetchUserRole();
        }).catch((error) => {
            toast.error(error?.response?.data?.message || error.message); ;
        });
    }

    const fetchUserRole = () => {
        setLoading(true);
        getClaims().then((idTokenResult) => {
            setLoading(false);
            setOpenAuth(false);
            if (!localStorage.getItem('uid')) {
                localStorage.setItem('uid', idTokenResult.claims.user_id);
                handleSuccess("You've successfully authenticated")
                navigate("/student-panel/dashboard")
            }
            if (idTokenResult.claims.tutor) {
                setUserRole("tutor");
                navigate("/tutor-panel/dashboard")
            }
            else if(idTokenResult.claims.student){
                setUserRole("student")
            }
          
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
        });
    }

    const openAuthPopup = () => {
        setOpenAuth(true);
        showView("login");
    }

    const handleError = (errorMsg) => {
        toast.error(errorMsg); // Display toast notification
    };

    const handleSuccess = (successMsg) => {
        toast.success(successMsg)
    }


    const logoutProfile = () => {
        localStorage.clear();
        logout();
        // navigate("/");
        setUserRole(null);
    }

    useEffect(() => {
        console.log(currentUser, "Current User")
        if (currentUser) {
            fetchUserRole();
        }
    }, []);

    return (
        <>
           <div className={home ? "header" : "main-navbar"}>
                <img className = {home ? "": "navbar-logo"} src={home ? logo: logologin} alt="Music Seekho Logo"></img>
                <div className="navbar-heading">
                    {!currentUser && (
                        <Link to="/" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Home</Link>
                    )}
                    {!currentUser && (
                        <Link to="https://forms.gle/uHxwsV1PxYTRUVj2A" target="_blank" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Start teaching</Link>
                    )}
                    {(!(currentUser) || (currentUser && userRole !== 'tutor')) && (
                        <Link to="/tutors" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Find tutor</Link>
                    )}
                    {currentUser && userRole === 'tutor' && <>
                        <Link to="/tutor-panel/dashboard" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Dashboard</Link>
                        <Link to="/tutor-panel/schedule" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Schedule</Link>
                        <Link to="/tutor-panel/history" className={`link-heading ${home ? '' : 'main-link-heading'}`}>History</Link>
                    </>}
                    {currentUser && (userRole === 'student' || userRole === 'demo') && <>
                        <Link to="/student-panel/dashboard" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Dashboard</Link>
                    </>}
                    {currentUser && (userRole === 'student') && <>
                        <Link to="/student-panel/schedule" className={`link-heading ${home ? '' : 'main-link-heading'}`}>Schedule</Link>
                    </>}
                    <Link className={`link-heading ${home ? '' : 'main-link-heading'}`}>Contact Us</Link>
                    {currentUser ? (
                        <Link onClick={logoutProfile} className={`link-heading ${home ? 'login-heading' : 'main-link-heading login-main'}`}>Logout</Link>
                    ) : (
                        <Link onClick={openAuthPopup} className={`link-heading ${home ? 'login-heading' : 'main-link-heading login-main'}`}>Login</Link>
                    )}
                </div>
                <div className="hamburger-icon" onClick={toggleMenu}>
                   <img className = "hamburger-img" src = {hamburger} alt = "hamburger"></img>
                </div>

                <div className={`drawer ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <button className="close-drawer" onClick={toggleMenu}>✕</button>
        <Link to="/" className="link-heading" >Home</Link>
        {currentUser ? (
                        <Link onClick={logoutProfile} className="login-heading link-heading">Logout</Link>
                    ) : (
                        <Link onClick={openAuthPopup} className="login-heading link-heading">Login</Link>
                    )}
        <Link to="/tutors" className="link-heading">Find tutor</Link>
        {currentUser && userRole === 'tutor' && (
            <>
                <Link to="/tutor-panel/dashboard" className="link-heading">Dashboard</Link>
                <Link to="/tutor-panel/schedule" className="link-heading">Schedule</Link>
                <Link to="/tutor-panel/history" className="link-heading">History</Link>
            </>
        )}
        {currentUser && userRole !== 'tutor' && (
            <>
                <Link to="/student-panel/dashboard" className="link-heading">Dashboard</Link>
                <Link to="/student-panel/schedule" className="link-heading">Schedule</Link>
            </>
        )}
        <Link to="contact-us" className="link-heading">Contact Us</Link>
    </div>
            </div>
            <Modal show={openAuth} onHide={() => setOpenAuth(false)} >
                <Modal.Header closeButton>
                    <img className="auth-main-logo" src={logologin} alt="Music Seekho Logo in Auth"></img>
                </Modal.Header>
                <Modal.Body >
                    <div>
                        <p className="auth-sub-heading">WELCOME BACK 👋🏻</p>
                        <p className="auth-main-heading">Continue to your Account.</p>
                        {
                            (state.login || state.signup) &&
                            <div className="social-logins">
                                <Button className="google-login" onClick={googleLogin} >
                                    <span className="continue-with-google"><span><img src={google} alt="Google Login in music learning platform, Music Seekho"></img></span>
                                        {
                                            (state.login || state.otpLogin) && <>Login </>
                                        }
                                        {
                                            state.signup && <>Signup </>
                                        }
                                        with Google</span></Button>
                                <Button onClick={() => showView('otpLogin')} className="google-login">
                                    <span className="continue-with-google">
                                        <img src={phoneicon} alt="Phone Login in music learning platform, Music Seekho"></img>

                                        {
                                            (state.login || state.otpLogin) && <>Login </>
                                        }

                                        {
                                            state.signup && <>Signup </>
                                        }
                                        with OTP</span></Button>
                            </div>
                        }

                        {
                            (state.login) &&
                            <Login closeModal={() => setOpenAuth(false)} handleError={handleError} handleSuccess={handleSuccess}></Login>

                        }

                        {
                            (state.signup) &&
                            <Signup closeModal={() => setOpenAuth(false)} handleError={handleError} handleSuccess={handleSuccess}></Signup>
                        }

                        {
                            (state.otpLogin) &&
                            <div>
                                <PhoneAuth closeModal={() => setOpenAuth(false)} handleError={handleError} handleSuccess={handleSuccess} ></PhoneAuth>
                                <div className="social-logins">
                                    <Button className="google-login" onClick={googleLogin} >
                                        <span className="continue-with-google"><span><img src={google} alt = "Google Login"></img></span>  Login with Google</span></Button>
                                    <Button onClick={() => showView('login')} className="google-login">
                                        <span className="continue-with-google"><img src={phoneicon} alt = "Login with Email"></img>Login with Email</span></Button>
                                </div>
                            </div>
                        }

                        {
                            (state.forgotPass) && <ForgotPassword handleError={handleError} handleSuccess={handleSuccess}></ForgotPassword>
                        }
                        <div className="auth-footer">
                            {
                                (state.login) && <div className="forgot-password">
                                    <span onClick={() => showView('forgotPass')} className="auth-text">Forgot Password?</span>
                                </div>
                            }

                            {
                                (state.login || state.otpLogin) && <div className="login-signup">
                                    Are You a Newbie? <Link onClick={() => showView('signup')} className="auth-bold">GET STARTED</Link>
                                </div>
                            }

                            {
                                (state.signup || state.forgotPass) && <div className="login-signup">
                                    Already have an account? <Link onClick={() => showView('login')} className="auth-text auth-bold">Login</Link>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
        </>
    )
}
export default Header;

import { React, useEffect, useState, useRef, api, API_URL, Modal, PuffLoader, override, ToastContainer, toast, StudentSidebar } from "../../../services/common";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Button from "@mui/material/Button";
import { Rating, TextField } from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { useParams, Link, useLocation } from 'react-router-dom';
import studentcounticon from "../../../assets/images/student-purple.svg";
import classcount from "../../../assets/images/classcount.svg";
import studentcount from "../../../assets/images/student-count.svg";
// import levels from "../../../assets/images/levels.svg";
import "./StudentCourseDetails.css";


const StudentCourseDetails = (props) => {
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(5);
    const [tutor, setTutor] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [reviews, setReviews] = useState([]);
    const [avgRating, setAvgRating] = useState(0);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [lesson, setLesson] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [studentReview, setStudentReview] = useState(null);
    const [studentImage, setStudentImage] = useState(null);
    const location = useLocation();
    const reviewimage = "https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/depositphotos_137014128-stock-illustration-user-profile-icon.jpg?alt=media&token=984c7401-9ccb-4d16-8509-24589d3f335b"
    const tutorData = location.state?.tutorData || null;
    const [classDate, setClassDate] = useState(tutorData?.date ? dayjs(new Date(tutorData?.date)) : dayjs(new Date()));
    const requestAbortController = useRef(null);
    let id = localStorage.getItem('uid');
    const { tutorId, course } = useParams();

    const fetchTutor = () => {
        setLoading(true);
        api.get(API_URL + `/tutors/view-tutor/` + tutorId)
            .then((response) => {
                setLoading(false);
                setTutor(response.data);
                courseData(response.data);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error)
            });
    }

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const calculateAverageRating = (reviews) => {
        if (!reviews) {
            return 0; // or handle the case when there are no reviews
        }
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = totalRating / reviews.length;
        return Number(averageRating % 1 !== 0 ? averageRating.toFixed(2) : averageRating);
    };

    const courseData = (courseInfo) => {
        const currentCourse = courseInfo.courses?.find(c => c.id === course);
        const reviews = courseInfo?.reviews?.filter(r => r.course === course).reverse();
        const studentRev = reviews?.find(review => review.studentId === id);
        const updatedCourse = {
            ...currentCourse,
            reviews: reviews,
        };

        setReviews(reviews);
        if (studentRev) {
            setComment(studentRev.review);
            setRating(studentRev.rating);
            setStudentReview(studentRev);
        }

        const rating = calculateAverageRating(reviews);
        setAvgRating(rating);
        setSelectedCourse(updatedCourse);
        studentProfile();
    }

    const renewNow = () => {
        const url = `/tutors/${tutorId}/${course}?source=renew`;
        window.open(url, '_blank'); 
    }

    const studentProfile = () => {
        const image = localStorage.getItem('image');
        if (image && image !="undefined") {
            setStudentImage(image);
        }
        else {
            setLoading(true);
            api.get(API_URL + "/students/view-student/" + id).then((response) => {
                setLoading(false);
                if(response.data?.image){
                setStudentImage(response.data?.image || "https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/depositphotos_137014128-stock-illustration-user-profile-icon.jpg?alt=media&token=984c7401-9ccb-4d16-8509-24589d3f335b");
                  localStorage.setItem("image", response.data?.image);
                }
                
            })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error?.response?.data?.message || error.message); ;
                    console.log(error);
                });
        }
    }

    const fetchHighlightedDays = (date) => {
        const controller = new AbortController();
        fetchAttendedClasses(date, {
            signal: controller.signal,
        })
            .then(({ daysToHighlight }) => {
                setHighlightedDays(daysToHighlight);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                // ignore the error if it's caused by `controller.abort`
                if (error.name !== 'AbortError') {
                    throw error;
                }
            });

        requestAbortController.current = controller;
    };

    const fetchAttendedClasses = (date) => {
        return new Promise((resolve, reject) => {
            const storedData = localStorage.getItem('cachedApiData');
            if (storedData) {
                // If API data is already available, use it
                const apiData = JSON.parse(storedData);
                const daysToHighlight = fillDaysToHighlight(apiData, date);
                resolve({ daysToHighlight });
            } else {
                setLoading(true);
                const params = {
                    tutor: tutorId // Make sure tutorId is defined somewhere in your code
                };
                api.get(API_URL + `/students/view-attended-schedule/` + id, { params })
                    .then((response) => {
                        setLoading(false);
                        const apiData = response.data;
                        localStorage.setItem('cachedApiData', JSON.stringify(apiData));
                        const daysToHighlight = fillDaysToHighlight(apiData, date);
                        resolve({ daysToHighlight });
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(error?.response?.data?.message || error.message); ;
                        reject(error);
                    });
            }
        });
    }

    const fillDaysToHighlight = (data, date) => {
        date = date.$d;
        const currentMonth = date.getMonth(); // Month is 0-indexed
        const daysToHighlight = [];
        for (const entry of data) {
            const entryDate = new Date(entry.date);
            const entryMonth = entryDate.getMonth(); // Month is 0-indexed
            if (entryMonth === currentMonth) {
                const entryDay = entryDate.getUTCDate();
                daysToHighlight.push(entryDay);
            }
        }
        return daysToHighlight;
    }

    const addReview = () => {
        setLoading(true);
        let body = {
            studentId: id,
            rating: rating,
            review: comment,
            course: course
        }
        api.post(API_URL + "/tutors/add-tutor-review/" + tutorId, body).then(() => {
            toast.success("Review Added Successfully");
            setLoading(false);
            fetchTutor();
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error)
            });
    }

    const handleMonthChange = (date) => {
        if (requestAbortController.current) {
            requestAbortController.current.abort();
        }
        setClassDate(date)
        setLoading(true);
        setHighlightedDays([]);
        fetchHighlightedDays(date);
    };

    const changeDate = (date) => {
        setLoading(true);
        setClassDate(date);
        const params = {
            date: date,
            course: course,
            tutor_id: tutorId
        };
        api.get(API_URL + "/students/view-lesson/" + id, { params }).then((response) => {
            setLoading(false);
            setLesson(response.data);
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error)
            });
    };

    useEffect(() => {
        fetchHighlightedDays(classDate);
        fetchTutor();
        changeDate(classDate);
        return () => requestAbortController.current?.abort();
    }, []);


    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected =
            !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <circle cx="4" cy="4" r="4" fill="#55BE24" />
                </svg> : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    return (
        <>
            <div className="row main-frame">
                <div className="col-3 padding-remove course-details-sidebar">
                    <StudentSidebar></StudentSidebar>
                </div>
                <div className="col-9 mobile">
                    <div className = "course-banner">
                    <div className="row">
                        <div className="col-7 mobile">
                            <div className="course-details-banner">
                                <span className="course-title-details"> {selectedCourse?.name}</span>
                                <Link to="/student-panel/history">
                                    <svg className="arrow-icon-details" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M25.3329 14.6666H10.4396L16.9462 8.15997C17.4662 7.63997 17.4662 6.78663 16.9462 6.26663C16.8229 6.14303 16.6764 6.04497 16.5151 5.97806C16.3538 5.91115 16.1809 5.87671 16.0062 5.87671C15.8316 5.87671 15.6587 5.91115 15.4974 5.97806C15.3361 6.04497 15.1896 6.14303 15.0662 6.26663L6.27957 15.0533C6.15597 15.1767 6.05791 15.3232 5.991 15.4845C5.92409 15.6458 5.88965 15.8187 5.88965 15.9933C5.88965 16.1679 5.92409 16.3408 5.991 16.5021C6.05791 16.6634 6.15597 16.8099 6.27957 16.9333L15.0662 25.72C15.1897 25.8434 15.3362 25.9413 15.4975 26.0081C15.6588 26.0749 15.8317 26.1093 16.0062 26.1093C16.1808 26.1093 16.3537 26.0749 16.515 26.0081C16.6763 25.9413 16.8228 25.8434 16.9462 25.72C17.0697 25.5965 17.1676 25.45 17.2344 25.2887C17.3012 25.1274 17.3356 24.9545 17.3356 24.78C17.3356 24.6054 17.3012 24.4325 17.2344 24.2712C17.1676 24.11 17.0697 23.9634 16.9462 23.84L10.4396 17.3333H25.3329C26.0662 17.3333 26.6662 16.7333 26.6662 16C26.6662 15.2666 26.0662 14.6666 25.3329 14.6666Z" fill="white" />
                                    </svg></Link>
                                <div className="by-tutor">
                                    By {tutor.name}
                                </div>
                                <span>
                                    {/* <span className="course-stats">
                                        <svg className="icon-course-stats" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                            <path d="M6.99967 0.833252C3.33301 0.833252 0.333008 3.83325 0.333008 7.49992C0.333008 11.1666 3.33301 14.1666 6.99967 14.1666C10.6663 14.1666 13.6663 11.1666 13.6663 7.49992C13.6663 3.83325 10.6663 0.833252 6.99967 0.833252ZM9.79967 10.2999L6.33301 8.16659V4.16659H7.33301V7.63325L10.333 9.43325L9.79967 10.2999Z" fill="white" />
                                        </svg>
                                        2 Weeks
                                    </span> */}
                                   {selectedCourse.students && <span className="course-stats">
                                        <img className="icon-course-stats" src={studentcount} alt="Icon for Students"></img>
                                        {selectedCourse.students} Students
                                    </span>}
                                    {/* <span className="course-stats">
                                        <img className="icon-course-stats" src={levels} alt="Icon for Levels"></img>
                                        All levels
                                    </span> */}
                                </span>
                            </div>
                        </div>

                        <div className="col-5 course-sidebar" >
                            <div className = "flex-col">
                                <div>      <img className="course-details-image" src={selectedCourse?.image} alt="Course Details of our e learning platform"></img></div>
                      
                            <div className="course-renew">
                            {tutorData?.validity && <span className="validity">Valid Till: {tutorData?.validity}</span>}
                                <Button onClick = {renewNow} variant="contained" className="renew-now">Renew Now</Button>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="row course-detail-dashboard">
                        <div className="col-4 mobile">
                            <div className="calendar-progress">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        value={classDate}
                                        loading={loading}
                                        onChange={changeDate}
                                        onMonthChange={handleMonthChange}
                                        renderLoading={() => <DayCalendarSkeleton />}
                                        slots={{
                                            day: ServerDay,
                                        }}
                                        slotProps={{
                                            day: {
                                                highlightedDays,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="progress-section">
                                <span className="course-progress">
                                    <span className="progress-heading">Progress</span>
                                    <progress value={tutorData?.class_attended} max={tutorData?.total_class} />
                                </span>
                                <span className="class-count-course">
                                    <img src={classcount} alt="Class count of Attended classes"></img>
                                    {tutorData?.class_attended}/{tutorData?.total_class}
                                </span>
                            </div>
                        </div>

                        <div className="col-3 mobile slot-mobile">
                            <div className="slot-container width-50">
                                <span className="green-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#55BE24" />
                                    </svg>
                                </span>
                                <span>
                                    <div className="slot-name">
                                        Slot Name
                                    </div>
                                    <div className="slot-timing">
                                        10:00AM - 10:30AM
                                    </div>
                                </span>
                            </div>

                            <svg className="slot-note-divider" xmlns="http://www.w3.org/2000/svg" width="257" height="2" viewBox="0 0 257 2" fill="none">
                                <path d="M0 1H257" stroke="black" strokeOpacity="0.1" />
                            </svg>
                            <div className = "width-50">
                                <span className="notes-lesson">Notes</span>
                                <div className="course-lesson">
                                    {
                                        lesson && lesson.map((less, index) => (
                                            <>
                                                <div key = {index} className="lesson-text">{less?.text}</div>
                                                <div className="lesson-images">
                                                    {
                                                        less?.imageUrl && less.imageUrl.length > 0
                                                            ? less.imageUrl.map((url, index) => (
                                                                <img key={index} className="lesson-image" src={url} onClick={() => setSelectedLesson(url)} alt="Lesson shared by tutor" />
                                                            ))
                                                            : 'No images'
                                                    }
                                                </div>
                                            </>
                                        ))}
                                    {
                                        lesson.length === 0 &&
                                        <div className="lesson-text">No New Notes to Display</div>
                                    }
                                    <Modal show={selectedLesson} onHide={() => setSelectedLesson(null)} size="lg">
                                        <img src={selectedLesson} alt="Selected Notes to View" />
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div className="col-5 course-information mobile" >
                            <div className="course-tabs-details ">
                                <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                                    <Tab label="Overview" />
                                    <Tab label="Instructor" />
                                    <Tab label="Reviews" />
                                </Tabs>
                                <div className=" course-section course-tutor-section mobile">
                                    {tab === 0 && <Typography>{selectedCourse.description}</Typography>}
                                    {tab === 1 && <>

                                        <div className="flex">
                                            <img className="tutor-image-course" src={tutor.image} alt="Tutor of the respective Course"></img>
                                            <div className="tutor-section-course">
                                                {tutor.name}
                                                <span>
                                                    <img src={studentcounticon} alt="Total Students enrolled for the course"></img>
                                                    <span className="student-count">{selectedCourse.students} students</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="about-tutor">
                                            {tutor.about}
                                        </div>
                                    </>}
                                    {tab === 2 && <>
                                        <div className="flex current-rating">
                                            <span className="avg-rating">{avgRating}</span>
                                            <div>
                                                <Rating name="read-only" value={avgRating} readOnly />
                                                <div className="base-rating">
                                                    based on {reviews?.length || 0} ratings
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-review">
                                            <div className="flex">
                                                <img className="review-image" src={studentImage || reviewimage} alt="Student who's review is shown"></img>
                                                <div>
                                                    <Rating
                                                        name="simple-controlled"
                                                        value={rating}
                                                        onChange={(event, newValue) => {
                                                            setRating(newValue);
                                                        }}
                                                    />
                                                    <TextField className="review-field" id="standard-basic" label="Add Comment" variant="standard" value={comment} onChange={(event) => setComment(event.target.value)} multiline rows={3} />
                                                    <div className="review-section">
                                                        <Button onClick={addReview} className="submit-review" >
                                                            {studentReview ? 'Edit Review' : 'Submit Review'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {reviews && reviews.map((review, index) => (
                                            <div key = {index} className="add-review">
                                                <div className="flex">
                                                    <img className="review-image" src={review?.image || reviewimage} alt="Current Review Poster"></img>
                                                    <div>
                                                        <div className="flex-align">
                                                            <span className="review-name">{review.name}</span>
                                                            <span className="review-date">{review?.reviewDate}</span>
                                                        </div>
                                                        <div className="review-date">
                                                            {review.review}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
        </>

    )
}

export default StudentCourseDetails;
import { React, useState, useEffect, PuffLoader, override, ToastContainer, api, API_URL, toast } from "../../../services/common";
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import "./TutorAppointment.css"


const BasicLayout = ({ onFieldChange, onDelete, appointmentData, ...restProps }) => {
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Asia/Kolkata' };
    const [loading, setLoading] = useState(false);
    const [batch, setBatch] = useState(appointmentData?.batch_limit ? true : false);
    const [batchLimit, setBatchLimit] = useState(appointmentData?.batch_limit)
    const [demo, setDemo] = useState(appointmentData?.demo);
    const [selectedDate, setSelectedDate] = useState(dayjs(appointmentData.startDate));
    const [startDate, setStartDate] = useState(new Date(appointmentData.startDate))
    const [courseNames, setCourseNames] = useState([]);
    const [endDate, setEndDate] = useState(() => {
        const startDate = new Date(appointmentData.startDate);
        const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Add one hour
        return endDate;
    });
    const startTime = (new Date(appointmentData.startDate)).toLocaleTimeString('en-IN', timeOptions);
    const endTime = (new Date(appointmentData.endDate)).toLocaleTimeString('en-IN', timeOptions);
    const id = localStorage.getItem('uid');
    const students = appointmentData?.students || [];
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(-1);
    const [courseId, setCourseId] = useState(appointmentData.course)
    const [slot, setSlot] = useState('');
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        slot: '',
        course: '',
        demo: false,
        batch_limit: undefined,
        title: ''
    });

    const handleFieldChange = (field, value) => {
        setFormData(prev => {
            const updatedData = { ...prev, [field]: value };
            onFieldChange(updatedData);
            return updatedData;
        });
    };

    const combineDateAndTime = (date) => {
        const startDate = new Date(date);
        const endDate = new Date(date);
        // Add one hour to the endDate
        endDate.setHours(endDate.getHours() + 1);
        // Format time to 24-hour format, including minutes
        const formatTime = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        return {
            startDate: startDate,
            endDate: endDate,
            slot: `${formatTime(startDate)}-${formatTime(endDate)}`
        };
    };

    const demoChange = (event) => {
        const demoValue = event.target.checked
        setDemo(demoValue);
        handleFieldChange("demo", event.target.checked);
    }

    const updateCourse = (event) => {
        const index = event.target.value;
        setSelectedCourse(index);
        const course = courses[index].id
        setCourseId(course);
        handleFieldChange("course", course);
        handleFieldChange("title", courseNames[course] || '');
    }

    const updateBatchLimit = (event) => {
        const batchNumber = event.target.value;
        setBatchLimit(batchNumber);
        handleFieldChange("batch_limit", event.target.value ? Number(event.target.value) : undefined);
    }

    const batchChange = (event) => {
        setBatch(event.target.checked);
    }

    const onDateChange = (date) => {
        date = dayjs(date);
        const { startDate, endDate, slot } = combineDateAndTime(date.toDate());
        setSlot(slot)
        setSelectedDate(date);
        setStartDate((new Date(date.$d)).toLocaleDateString('en-IN', dateOptions));
        setEndDate(endDate)
        handleFieldChange("startDate", startDate);
    handleFieldChange("endDate", endDate);
    handleFieldChange("slot", slot);
    }

    const getCourses = () => {
        setLoading(true);
        api.get(API_URL + "/tutors/view-tutor/" + id).then((response) => {
            setLoading(false);
            if (response.data.courses) {
                const updatedCourses = response.data.courses;
                setCourses(updatedCourses);
                const courseIdToNameMap = updatedCourses.reduce((acc, course) => {
                    acc[course.id] = course.name; // assuming 'name' is the property for course name
                    return acc;
                }, {});
                setCourseNames(courseIdToNameMap)
                setSelectedCourse(updatedCourses.findIndex(obj => obj.id === appointmentData.course))
                const course = appointmentData.course || updatedCourses[0].id
                setCourseId(course)
                const initialObject = {
                    startDate: appointmentData.startDate,
                    endDate: appointmentData.endDate,
                    slot: `${startTime}-${endTime}`,
                    course,
                    title: courseIdToNameMap[course] || ''
                }
                setFormData(initialObject);
                onFieldChange(initialObject);
            }
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error.message);;
                setLoading(false);
                console.log(error)
            });
    }

    useEffect(() => {
        setSlot(`${startTime}-${endTime}`);

        getCourses()

    }, []);


    return (
        <>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
            <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps}>
                <span className="schedule-heading">
                    <span className="mr-1">{appointmentData.title} Class</span>

                    {courses.length === 0 ? (
                        <span> {appointmentData.course}</span>
                    ) : (
                        <span className="course-dropdown">
                            <select
                                id="courseDropdown"
                                value={selectedCourse}
                                onChange={updateCourse}
                            >
                                <option value="" disabled>Select a Course</option>
                                {courses.map((course, index) => (
                                    <option key={course?.id} value={index}>
                                        {course?.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                    )}
                </span>
                <div>
                    <span className="time-slot">{slot}</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                        <DemoItem>
                            <DateCalendar value={selectedDate} onChange={onDateChange} />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>

                <div className="flex time-picker">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>

                            <TimePicker
                                className="create-class-label" label="Select Time"
                                value={selectedDate} onChange={onDateChange}
                            />

                        </DemoContainer>
                    </LocalizationProvider>


                    <input type="checkbox" onChange={demoChange} checked={demo} />
                    Demo Class


                    <input type="checkbox" checked={batch} onChange={batchChange} />
                    Batch Limit
                    {batch && <input type="number" min={1} max={10} value={batchLimit} onChange={updateBatchLimit} />}
                    {students.length > 0 && <div className="create-class-label select-students">Students: {students.length}</div>}
                </div>
            </AppointmentForm.BasicLayout>
        </>


    );
}

export default BasicLayout;
import { React, useEffect, useState, useRef, api, API_URL, Modal, PuffLoader, override, ToastContainer, toast, TutorSidebar } from "../../../services/common";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Button from "@mui/material/Button";
import { TextField, List, ListItem, ListItemText, ListItemIcon, Checkbox, Rating } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { useParams, Link, useLocation } from 'react-router-dom';
import * as TableMaterialUI from '@mui/material';
import Paper from '@mui/material/Paper';
import reviewimage from "../../../assets/images/review-image.jpg"
import defaultimage from "../../../assets/images/uploadimage.png";
import uploadbutton from "../../../assets/images/upload-button.png";
import studentcount from "../../../assets/images/student-count.svg";
import cross from "../../../assets/images/cross.svg"
import editcourse from "../../../assets/images/edit-image.svg"
import "./TutorCourseDetails.css";

const { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } = TableMaterialUI;
const TutorCourseDetails = (props) => {
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tutor, setTutor] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [reviews, setReviews] = useState([])
    const [avgRating, setAvgRating] = useState(0);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [lesson, setLesson] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [studentImage, setStudentImage] = useState(null);
    const location = useLocation();
    const [notesModal, setNotesModal] = useState(false);
    const [studentModal, setStudentModal] = useState(false);
    const courseData = location.state?.courseData || null;
    const [selectedStudent, setSelectedStudent] = useState({});
    const [imageUrls, setImageUrls] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [classDate, setClassDate] = useState(courseData?.date ? dayjs(new Date(courseData?.date)) : dayjs(new Date()));
    const requestAbortController = useRef(null);
    const [schedule, setSchedule] = useState([])
    const [attendedStudents, setAttendedStudents] = useState([]);
    let id = localStorage.getItem('uid');
    const fileInputRef = useRef(null);
    const [about, setAbout] = useState('')
    const [students, setStudents] = useState([])
    const [courseStudents, setCourseStudents] = useState([])
    const [editCourse, setEditCourse] = useState(false)

    const { tutorId, course } = useParams();



    const handleAboutChange = (event) => {
        setAbout(event.target.value);
    };

    const datePipe = (isoString) => {
        if (isoString) {
            const date = new Date(isoString);
            const format = date.toLocaleString('en-IN', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit', minute: '2-digit', hour12: false
            });
            return format
        }
        else return ''
    };



    const formatDateTime = (isoString) => {
        const dateObj = new Date(isoString);
        const formattedDate = dateObj.toISOString().split('T')[0]; // yyyy-mm-dd format
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedTime = dateObj.toLocaleTimeString('en-US', options); // hh:mm AM/PM format
        return { date: formattedDate, time: formattedTime };
    };



    const fetchTutor = () => {
        api.get(API_URL + `/tutors/view-tutor/` + tutorId)
            .then((response) => {
                setLoading(false);
                setTutor(response.data);
                setStudents(response.data?.students);
                filterCourseStudents(response.data?.students);
                courseDetails(response.data);
                fetchHighlightedDays(classDate);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error)
            });
    }

    const filterCourseStudents = (students) => {
        const filteredStudents = students?.filter(student => student.course === course)
        setCourseStudents(filteredStudents)
    }

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const sendNotes = () => {
        setLoading(true);
        const body = {
            students: selectedStudents,
            lesson: {
                text: about,
                imageUrl: imageUrls,
            },
            course: course
        }

        api.post(API_URL + "/tutors/send-lesson/" + tutorId, body).then((response) => {
            toast.success("Notes Sent");
            setLoading(false);
            setNotesModal(false);
            changeDate(classDate)
        })
            .catch((error) => {
                console.log(error);
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
                setNotesModal(false);
            });

    }

    const handleStudentChange = (studentId) => {
        const currentIndex = selectedStudents.indexOf(studentId);
        const newSelectedStudents = [...selectedStudents];

        if (currentIndex === -1) {
            newSelectedStudents.push(studentId);
        } else {
            newSelectedStudents.splice(currentIndex, 1);
        }

        setSelectedStudents(newSelectedStudents);

    }

    const saveCourse = () => {
        const body = {
            image: selectedCourse.image,
            description: selectedCourse.description,
            name: selectedCourse.name
        }
        api.put(API_URL + `/tutors/update-course/${tutorId}/${course}`, body).then((response) => {
            toast.success("Course Details Updated");
            setLoading(false);
            setEditCourse(false);
        })
            .catch((error) => {
                console.log(error);
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
            });
    }


    const calculateAverageRating = (reviews) => {
        if (!reviews) {
            return 0; // or handle the case when there are no reviews
        }
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = totalRating / reviews.length;
        return averageRating % 1 !== 0 ? averageRating.toFixed(2) : averageRating;
    };

    const courseDetails = (courseInfo) => {
        const currentCourse = courseInfo.courses.find(c => c.id === course);
        const reviews = courseInfo?.reviews?.filter(r => r.course === course).reverse();
        const updatedCourse = {
            ...currentCourse,
            reviews: reviews,
        };

        setReviews(reviews);
        const rating = calculateAverageRating(reviews);
        setAvgRating(rating);
        setSelectedCourse(updatedCourse);
    }


    const courseDetailChange = (e) => {
        const { name, value } = e.target
        setSelectedCourse({
            ...selectedCourse,
            [name]: value
        });
    };

    const studentProfile = (student) => {
        setLoading(true);
        api.get(API_URL + "/students/view-student/" + student).then((response) => {
            setLoading(false);
            setStudentImage(response.data?.image);
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error);
            });
    }


    const fetchHighlightedDays = (date) => {
        const controller = new AbortController();
        fetchAttendedClasses(date, {
            signal: controller.signal,
        })
            .then(({ daysToHighlight }) => {
                setHighlightedDays(daysToHighlight);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                // ignore the error if it's caused by `controller.abort`
                if (error.name !== 'AbortError') {
                    throw error;
                }
            });

        requestAbortController.current = controller;
    };

    const fetchAttendedClasses = (date) => {
        return new Promise((resolve, reject) => {
            const storedData = localStorage.getItem('cachedApiData');
            if (storedData) {
                // If API data is already available, use it
                const apiData = JSON.parse(storedData);
                setSchedule(apiData)
                const daysToHighlight = fillDaysToHighlight(apiData, date);
                resolve({ daysToHighlight });
            } else {
                setLoading(true);
                const params = {
                    course: course,
                };
                api.get(API_URL + `/tutors/view-schedule/` + id, { params })
                    .then((response) => {
                        setLoading(false);
                        const apiData = response.data;
                        apiData.forEach((schedule) => {
                            if (schedule.students) {
                                const studentIds = schedule.students;
                                const studentNames = studentIds.map(id => {
                                    const studentObj = students.find(student => student.id === id);
                                    return studentObj ? studentObj.name : 'student';
                                });
                                schedule.studentNames = studentNames
                            }
                            if (schedule.date) {
                                const { date, time } = formatDateTime(schedule.date);
                                schedule.formattedDate = date;
                                schedule.formattedTime = time;
                            }
                        });
                        setSchedule(apiData)
                        localStorage.setItem('cachedApiData', JSON.stringify(apiData));
                        const daysToHighlight = fillDaysToHighlight(apiData, date);
                        resolve({ daysToHighlight });
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(error?.response?.data?.message || error.message); ;
                        reject(error);
                    });
            }
        });
    }

    const fillDaysToHighlight = (data, date) => {
        date = date.$d;
        const currentMonth = date.getMonth(); // Month is 0-indexed
        const daysToHighlight = [];
        for (const entry of data) {
            const entryDate = new Date(entry.date);
            const entryMonth = entryDate.getMonth(); // Month is 0-indexed
            if (entryMonth === currentMonth) {
                const entryDay = entryDate.getUTCDate();
                daysToHighlight.push(entryDay);
            }
        }
        return daysToHighlight;
    }

    const removeImage = (index) => {
        setImageUrls(imageUrls.filter((_, i) => i !== index));
    }

    const selectStudent = (student) => {
        const params = {
            tutor: tutorId,
            course: course
        }
        studentProfile(student.id);
        api.get(API_URL + `/students/view-attended-schedule/` + student.id, { params }).then((response) => {
            const studentBody = {
                ...student,
                classes: response.data
            }
            setSelectedStudent(studentBody)
            setStudentModal(true)
        })
            .catch((error) => {
                console.log(error.message)
            })
    }


    const handleMonthChange = (date) => {
        if (requestAbortController.current) {
            requestAbortController.current.abort();
        }
        setClassDate(date)
        setLoading(true);
        setHighlightedDays([]);
        fetchHighlightedDays(date);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e, source) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        api.post(API_URL + "/common/upload-image", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.image_url) {
                toast.success("Image Uploaded Successfully");
                if (source === 'notes') {
                    setImageUrls((prevImages) => [...prevImages, response.data.image_url])
                }

                else {
                    setSelectedCourse({
                        ...selectedCourse,
                        image: response.data.image_url,
                    })
                }

            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    };


    const formatStudentNames = (studentNames) => {
        if (studentNames.length <= 2) {
            return studentNames.join(', ');
        }
        return `${studentNames[0]}, ${studentNames[1]}, ... ${studentNames.length - 2} others`;
    };

    const formatDate = (date) => {
        const dateObj = new Date(date);
        const formattedDate = dateObj.toISOString().split('T')[0]; // yyyy-mm-dd format
        return formattedDate;
    };

    const changeDate = (date) => {
        setLoading(true);
        setClassDate(date);
        const formattedDate = formatDate(new Date(date.$d));
        const matchedSchedules = schedule.filter(sch => sch.formattedDate === formattedDate);

        if (matchedSchedules.length > 0) {
            const studentList = [];
            matchedSchedules.forEach(sch => {
                const time = sch.formattedTime;
                const studentNamesList = sch.studentNames;
                studentList.push({
                    [time]: studentNamesList
                })
            });
            setAttendedStudents(studentList);
        }
        else {
            setAttendedStudents([])
        }
        const params = {
            date: date,
            course: course,
        };
        api.get(API_URL + "/tutors/view-lesson/" + id, { params }).then((response) => {
            setLoading(false);
            const updatedLessons = response.data.map(lesson => {
                if (lesson.students) {
                    const studentIds = lesson.students;
                    const studentNames = studentIds.map(id => {
                        const studentObj = students.find(student => student.id === id);
                        return studentObj ? studentObj.name : 'student';
                    });
                    lesson.students = studentNames;
                }
                return lesson;
            });

            // Update your state for lessons
            setLesson(updatedLessons);



        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error)
            });
    };


    useEffect(() => {

        fetchTutor();
        changeDate(classDate);
        return () => requestAbortController.current?.abort();
    }, []);


    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected =
            !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <circle cx="4" cy="4" r="4" fill="#55BE24" />
                </svg> : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    return (
        <>
            <div className="row main-frame">
                <div className="col-3 padding-remove course-details-sidebar">
                    <TutorSidebar></TutorSidebar>
                </div>
                <div className="col-9 mobile">
                <div className = "course-banner">
                    <div className="row">
                        <div className="col-7 mobile">
                            <div className="course-details-banner">
                                {editCourse && <TextField className="course-title-edit"
                                    name="name"
                                    value={selectedCourse.name} onChange={courseDetailChange} variant="outlined"
                                />}
                                {!editCourse && <span className="course-title-details"> {selectedCourse.name}</span>}
                                <Link to="/tutor-panel/history">
                                    <svg className="arrow-icon-details" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M25.3329 14.6666H10.4396L16.9462 8.15997C17.4662 7.63997 17.4662 6.78663 16.9462 6.26663C16.8229 6.14303 16.6764 6.04497 16.5151 5.97806C16.3538 5.91115 16.1809 5.87671 16.0062 5.87671C15.8316 5.87671 15.6587 5.91115 15.4974 5.97806C15.3361 6.04497 15.1896 6.14303 15.0662 6.26663L6.27957 15.0533C6.15597 15.1767 6.05791 15.3232 5.991 15.4845C5.92409 15.6458 5.88965 15.8187 5.88965 15.9933C5.88965 16.1679 5.92409 16.3408 5.991 16.5021C6.05791 16.6634 6.15597 16.8099 6.27957 16.9333L15.0662 25.72C15.1897 25.8434 15.3362 25.9413 15.4975 26.0081C15.6588 26.0749 15.8317 26.1093 16.0062 26.1093C16.1808 26.1093 16.3537 26.0749 16.515 26.0081C16.6763 25.9413 16.8228 25.8434 16.9462 25.72C17.0697 25.5965 17.1676 25.45 17.2344 25.2887C17.3012 25.1274 17.3356 24.9545 17.3356 24.78C17.3356 24.6054 17.3012 24.4325 17.2344 24.2712C17.1676 24.11 17.0697 23.9634 16.9462 23.84L10.4396 17.3333H25.3329C26.0662 17.3333 26.6662 16.7333 26.6662 16C26.6662 15.2666 26.0662 14.6666 25.3329 14.6666Z" fill="white" />
                                    </svg></Link>
                                <div className="by-tutor">
                                    By {tutor.name}
                                </div>
                                <span>
                                    {/* <span className="course-stats">
                                        <svg className="icon-course-stats" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                            <path d="M6.99967 0.833252C3.33301 0.833252 0.333008 3.83325 0.333008 7.49992C0.333008 11.1666 3.33301 14.1666 6.99967 14.1666C10.6663 14.1666 13.6663 11.1666 13.6663 7.49992C13.6663 3.83325 10.6663 0.833252 6.99967 0.833252ZM9.79967 10.2999L6.33301 8.16659V4.16659H7.33301V7.63325L10.333 9.43325L9.79967 10.2999Z" fill="white" />
                                        </svg>
                                        2 Weeks
                                    </span> */}
                                    {selectedCourse.students && <span className="course-stats">
                                        <img className="icon-course-stats" src={studentcount} alt="Icon for Students"></img>
                                        {selectedCourse.students} Students
                                    </span>}
                                    {/* <span className="course-stats">
                                        <img className="icon-course-stats" src={levels} alt="Icon for Levels"></img>
                                        All levels
                                    </span> */}
                                </span>
                            </div>
                        </div>

                        <div className="col-5">
                            <div className="flex-row-custom">
                                <img className="course-details-image" name="image" src={selectedCourse?.image || 'https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/placeholder.jpg?alt=media&token=f59e4d05-d1e7-4d92-bffa-c27b1ca6941b'} alt="Course Details of our e learning platform"></img>
                                {editCourse && <img onClick={handleImageClick} className="edit-image" src={editcourse} alt = "Edit Tutor Profile"></img>}
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={(e) => handleFileChange(e, 'course-image')}
                                />
                            </div>

                            <div className="flex-col">
                                <div className="edit-course-section">
                                    {!editCourse && <Button className="join-class save-button course-buttons" variant="contained" onClick={() => setEditCourse(true)}>Edit Course</Button>}
                                    {
                                        editCourse &&
                                        <div className="flex">
                                            <Button onClick={() => setEditCourse(false)} className="cancel-class cancel-button course-buttons ">
                                                Cancel
                                            </Button>
                                            <Button onClick={saveCourse} className="join-class save-button course-buttons" variant="contained" >
                                                Save
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="row course-detail-dashboard">
                        <div className="col-4 mobile">
                            <div className="calendar-progress">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        value={classDate}
                                        loading={loading}
                                        onChange={changeDate}
                                        onMonthChange={handleMonthChange}
                                        renderLoading={() => <DayCalendarSkeleton />}
                                        slots={{
                                            day: ServerDay,
                                        }}
                                        slotProps={{
                                            day: {
                                                highlightedDays,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div>
                                {attendedStudents && attendedStudents.length > 0 ? (
                                    attendedStudents.map((attendance, index) => {
                                        // Extract time and students from the attendance object
                                        const time = Object.keys(attendance)[0];
                                        const students = attendance[time];

                                        return (
                                            <div key={index} className="attended-students">
                                                <div className="student-attended-list"> Student List (Time: {time})</div>
                                                {students.length > 0 ? (
                                                    students.map((student, idx) => (
                                                        <div key={idx} className="attended-student">{student}</div>
                                                    ))
                                                ) : (
                                                    <div className="attended-students">No students attended</div>
                                                )}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="attended-students">No attended students to display</div>
                                )}
                            </div>
                        </div>

                        <div className="col-3 mobile">
                            {/* <div className="slot-container">
                                <span className="green-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#55BE24" />
                                    </svg>
                                </span>
                                <span>
                                    <div className="slot-name">
                                        Slot Name
                                    </div>
                                    <div className="slot-timing">
                                        10:00AM - 10:30AM
                                    </div>
                                </span>
                            </div> */}

                            <svg className="slot-note-divider" xmlns="http://www.w3.org/2000/svg" width="257" height="2" viewBox="0 0 257 2" fill="none">
                                <path d="M0 1H257" stroke="black" strokeOpacity="0.1" />
                            </svg>
                            <div className =  "notes-spacing">
                                <span className="notes-lesson">Notes</span>
                                <div className="course-lesson">
                                    {
                                        lesson && lesson.map((less) => (
                                            <div className="sent-notes">
                                                <div className="lesson-text">
                                                    Sent to:  <b>{
                                                        less?.students && less.students.length > 0
                                                            ? formatStudentNames(less.students)
                                                            : 'No students'
                                                    }</b>
                                                </div>
                                                <div className="lesson-text">{less?.lesson?.text}</div>
                                                <div className="lesson-images">
                                                    {
                                                        less?.lesson?.imageUrl && less.lesson.imageUrl.length > 0
                                                            ? less.lesson.imageUrl.map((url, index) => (
                                                                <img key={index} className="lesson-image" src={url} onClick={() => setSelectedLesson(url)} alt="Lesson shared by tutor" />
                                                            ))
                                                            : 'No images'
                                                    }
                                                </div>
                                            </div>


                                        ))}
                                    {
                                        lesson.length === 0 &&
                                        <div className="lesson-text">No New Notes to Display</div>
                                    }



                                    <Button onClick={() => setNotesModal(true)} >Send Notes</Button>

                                    <Modal show={notesModal} onHide={() => setNotesModal(false)} dialogClassName="modal-60w">
                                        <Modal.Header closeButton>
                                            <Modal.Title className="send-notes-title send-notes-spacing"> Send Notes</Modal.Title>
                                            <div className="action-buttons">
                                                <Button onClick={() => setNotesModal(false)} className="cancel-profile">
                                                    Cancel
                                                </Button>
                                                <Button className="save-profile" onClick={sendNotes}>
                                                    Send
                                                </Button>
                                            </div>
                                        </Modal.Header>
                                        <Modal.Body>



                                            <div className="create-class-label select-students-notes">Select Students</div>
                                            <List className="notes-list">

                                                {/* onClick={handleStudentChange(student.id)} */}
                                                {courseStudents && courseStudents.map((student) => (
                                                    <ListItem key={student?.id} >
                                                        <ListItemIcon className="student-checkbox">
                                                            <Checkbox
                                                                edge="start"
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${student.id}` }}
                                                                checked={selectedStudents.indexOf(student.id) !== -1}
                                                                onClick={() => handleStudentChange(student.id)}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText className="student-items" id={`checkbox-list-label-${student.id}`} primary={student?.name} />
                                                    </ListItem>
                                                ))}
                                            </List>


                                            <div className="attach-notes">

                                                Attach Images
                                                <div className="notes-images">
                                                    <img className="upload-notes" src={uploadbutton} alt="Profile" onClick={handleImageClick} />
                                                    {imageUrls.map((url, index) => (
                                                        <div className="notes-container">
                                                            <img className="upload-notes" key={index} src={url} alt={`Course ${index + 1}`} />
                                                            <img className="remove-note" src={cross} onClick={() => removeImage(index)} alt = "remove notes"></img>
                                                        </div>
                                                    ))}
                                                </div>



                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                                onChange={(e) => handleFileChange(e, 'notes')}
                                            />
                                            <TextField className="overview-container"
                                                id="outlined-multiline-static"
                                                label="About"
                                                multiline
                                                rows={2}
                                                variant="standard"
                                                value={about}
                                                onChange={handleAboutChange}
                                            />
                                        </Modal.Body>
                                    </Modal>
                                    <Modal show={selectedLesson} onHide={() => setSelectedLesson(null)} size="lg">
                                        <img src={selectedLesson} alt="Selected Notes to View" />
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div className="col-5 course-information mobile" >
                            <div className="course-tabs-details">
                                <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                                    <Tab label="Overview" />
                                    <Tab label="Students" />
                                    <Tab label="Reviews" />
                                </Tabs>
                                <div className=" course-section course-tutor-section mobile">
                                    {tab === 0 &&

                                        <div className="overview-container">
                                            <TextField className="overview-container"
                                                id="outlined-multiline-static"
                                                name="description"
                                                multiline
                                                rows={8}
                                                disabled={!editCourse}
                                                value={selectedCourse.description} onChange={courseDetailChange} variant="standard"
                                            />

                                        </div>
                                    }



                                    {tab === 1 && <>
                                        <div>

                                            <div className="count-pill">
                                                <div className="tutor-student-count">
                                                    <span className="student-count-text">Total Students ({selectedCourse?.students})</span>
                                                </div>
                                                {selectedCourse?.active_students &&  <div className="tutor-student-count">
                                                    <span className="student-count-text">Active Students ({selectedCourse?.active_students})</span>
                                                </div>}
                                            </div>
                                            <div>

                                                {courseStudents && courseStudents.map((student) => (
                                                    <div className="check-student-classes add-review" onClick={() => selectStudent(student)}>
                                                        <div className="flex" >
                                                            <div>
                                                                <div className="flex-align">
                                                                    <span className="review-name">{student?.name}</span>
                                                                </div>
                                                                <div className="review-date">
                                                                    Classes Attended {student?.class_attended}/ {student?.total_class}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>

                                            <Modal show={studentModal} onHide={() => setStudentModal(false)} dialogClassName="modal-25w">
                                                <Modal.Body>
                                                    <div className="flex">
                                                        <div>
                                                            <img className="student-detail" src={studentImage || defaultimage} alt="Profile" />
                                                        </div>
                                                        <div className="selected-student-details">
                                                            <div className="selected-student-name">
                                                                {selectedStudent?.name}
                                                            </div>
                                                            <div className="selected-student-validity">
                                                                {datePipe(selectedStudent?.validity)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="list-class">

                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Date</TableCell>
                                                                        <TableCell >Time</TableCell>
                                                                        <TableCell>Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedStudent && selectedStudent?.classes?.map((class_details) => (
                                                                        <TableRow
                                                                            key={class_details?.date}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell component="th" scope="row">
                                                                                {class_details?.date}
                                                                            </TableCell>
                                                                            <TableCell>{class_details?.time}</TableCell>
                                                                            <TableCell>{class_details?.status &&
                                                                                <span className="completed-class">Completed</span>
                                                                            }</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </>}
                                    {tab === 2 && <>
                                        <div className="flex current-rating">
                                            <span className="avg-rating">{avgRating}</span>
                                            <div>
                                                <Rating name="read-only" value={avgRating} readOnly />
                                                <div className="base-rating">
                                                    based on {reviews?.length} ratings
                                                </div>
                                            </div>
                                        </div>

                                        {reviews && reviews.map((review) => (
                                            <div className="add-review">
                                                <div className="flex">
                                                    <img className="review-image" src={review?.image || reviewimage} alt="Current Review Poster"></img>
                                                    <div>
                                                        <div className="flex-align">
                                                            <span className="review-name">{review.name}</span>
                                                            <span className="review-date">{review?.reviewDate}</span>
                                                        </div>
                                                        <div className="review-date">
                                                            {review.review}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
        </>

    )
}

export default TutorCourseDetails;
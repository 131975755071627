import { React, useEffect, useState, api, API_URL, toast, PuffLoader, override } from "../../../services/common";
import { Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import MainHeader from "../../Common/Header/MainHeader/MainHeader";
import experience from "../../../assets/images/experience.svg";
import rating from "../../../assets/images/rating.svg";
import "./TutorList.css";


const TutorList = () => {
    const [tutors, setTutors] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const viewSelectedTutor = (tutorId) => {
        navigate(`/tutors/${tutorId}`);
    }

    const viewTutors = () => {
        setLoading(true);
        api.get(API_URL + '/tutors/view-tutors').then((response) => {
            setLoading(false);
            const tutorsData = response.data;
            setTutors(tutorsData);
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error);
        });
    }

    useEffect(() => {
        viewTutors();
    }, []);


    return (
        <>
        <MainHeader></MainHeader>
            <div className="tutor-list">
                {tutors.map((tutor, index) => (
              <div key={index}>
                {tutor?.courses && tutor.courses.length && 
                        <Card className="tutor-cards" onClick={() => viewSelectedTutor(tutor.id)}>
                            <span className = "course-tag">{tutor?.courses[0]?.name} {tutor?.courses?.length > 1? ` + ${tutor?.courses?.length -1 } more ...`: ''}</span>
                            <Card.Img className="tutor-image" variant="left" src={tutor?.image || 'https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/placeholder.jpg?alt=media&token=f59e4d05-d1e7-4d92-bffa-c27b1ca6941b'} />
                            <Card.Body>
                                <Card.Title className="tutor-name"> {tutor.name}</Card.Title>
                                <Card.Title >
                                  {tutor?.experience && 
                                  <>
                                   <img className="tutor-icons" src={experience} alt = "Experienced tutors"></img>
                                   <span className="tutor-details">{tutor?.experience} years of experience</span>
                                  </>
                                  } 
                                </Card.Title>
                                <Card.Title>
                                 {tutor?.rating && <img className="tutor-icons" src={rating} alt = "Rating of the tutors"></img> }   
                                    <span className="tutor-details">{tutor?.rating || "New Tutor"}</span>  
                                </Card.Title>
                                <div className="view-more">View More</div>
                            </Card.Body>
                        </Card>
}
                    </div>

                    
                ))}
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>
    )
}

export default TutorList

import { React, useState, api, API_URL, toast } from "../../../../services/common";
import "react-toastify/dist/ReactToastify.css";

const AddTutorForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        experience: ""
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.phone || !formData.experience) {
            toast.error("All fields are required!");
            return;
        }

        setLoading(true);

        try {
             await api.post(API_URL + "/tutors/add-tutor", formData);
            toast.success("Tutor added successfully!");
            setFormData({ name: "", email: "", phone: "", experience: "" }); // Reset form
        } catch (error) {
            toast.error(error?.response?.data?.message || "Failed to add tutor");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-4 border rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Add Tutor</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    name="name"
                    placeholder="Tutor Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Tutor Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="tel"
                    name="phone"
                    placeholder="Tutor Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="number"
                    name="experience"
                    placeholder="Years of Experience"
                    value={formData.experience}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
                <button
                    type="submit"
                    className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
                    disabled={loading}
                >
                    {loading ? "Submitting..." : "Add Tutor"}
                </button>
            </form>
        </div>
    );
};

export default AddTutorForm;

import { React, useEffect, useState, useRef, Modal, api, API_URL, PuffLoader, override, ToastContainer, toast, TutorSidebar } from "../../../services/common"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TutorEarnings from "../TutorEarnings/TutorEarnings";
import Button from "@mui/material/Button";
import even from "../../../assets/images/even.svg";
import odd from "../../../assets/images/odd.svg";
import classcount from "../../../assets/images/classcount.svg"
import { useNavigate } from "react-router-dom";
import uploadbutton from "../../../assets/images/upload-button.png";
import { TextField } from '@mui/material';
import plus from "../../../assets/images/plus.svg";
import "./TutorHistory.css"

const TutorHistory = () => {
    const [tab, setTab] = useState(0);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState({
        name: "",
        image: "",
        description: ""
    })
    const [courseModal, setCourseModal] = useState(false);
    const navigate = useNavigate();
    let id = localStorage.getItem('uid');
    const fileInputRef = useRef(null);

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const openCourseDetails = (course) => {
        navigate(`/tutor-panel/history/${id}/${course.id}`, { state: { courseData: course } });
    };


    const handleImageClick = () => {
        fileInputRef.current.click();
    };


    const datePipe = (isoString) => {
        if (isoString) {
            const date = new Date(isoString);
            const format = date.toLocaleString('en-IN', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit', minute: '2-digit', hour12: false
            });
            return format
        }
        else return ''
    };


    const handleFileChange = (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        api.post(API_URL + "/common/upload-image", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.image_url) {
                toast.success("Image Uploaded Successfully");
                setCourse({
                    ...course,
                    "image": response?.data?.image_url
                })
            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    };

    const getCourses = () => {
        setLoading(true);
        api.get(API_URL + "/tutors/view-tutor/" + id).then((response) => {
            setLoading(false);
            if (response.data.courses) {
                const updatedCourses = response.data.courses;
                setCourses(updatedCourses);
            }
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
                console.log(error)
            });
    }

    const handleCourseChange = (e) => {
        const { name, value } = e.target
        setCourse({
            ...course,
            [name]: value
        })
    }

    const addCourse = () => {
        setLoading(true);
        const body = {
            name: course.name,
            description: course.description,
            image: course.image,
        }

        api.post(API_URL + "/tutors/add-course/" + id, body).then((response) => {
            setLoading(false);
            getCourses();
            setCourseModal(false)
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
                console.log(error)
            });
    }
    useEffect(() => {
        getCourses();
    }, []);

    return (
        <>
            <div className="row main-frame tutor-history-frame">
                <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
                <div className="col-3 padding-remove mobile">
                    <TutorSidebar></TutorSidebar>
                </div>
                <div className="col-9 mobile">
                    <div className="course-tabs">
                        <div className = "row">
                            <div className="col-9 mobile">
                                <Tabs value={tab} onChange={changeTab} className="course-tab-display">
                                    <Tab label="Courses" />
                                    <Tab label="Payments" />
                                </Tabs>
                            </div>
                           {tab === 0 &&  <div className="col-3 align-text-center mobile">
                                <Button className="create-class create-class-button" onClick={() => setCourseModal(true)}> 
                                <img className="plus-sign" src={plus} alt = "Create new music course"></img>
                                    Create New Course</Button>
                            </div> }
                        </div>



                        {tab === 0 && <div>
                            {courses.map((course, index) => (
                                <div key={index} className="course-history" onClick={() => openCourseDetails(course)}>
                                    <div className="course-bar">
                                        <img key={index} src={index % 2 === 0 ? even : odd} alt={`course ${index}`} />
                                        <div className="course-naming">
                                            <div className="course-name-bar">{course?.name}</div>
                                            <div className="course-progress">
                                                <div className="progress-heading">No of Students</div>
                                                {course?.students}
                                            </div>
                                        </div>
                                       <div>
                                       {course?.created_on &&  <span className="validity-date">
                                                Created on {datePipe(course?.created_on)}
                                            </span>}
                                            <div className="class-count">
                                                <img src={classcount} alt = "Music classes conducted by the tutor"></img>
                                                {course?.classes}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                        {tab === 1 &&
                            <TutorEarnings></TutorEarnings>
                        }
                    </div>
                </div>
                <Modal show={courseModal} onHide={() => setCourseModal(false)} dialogClassName="modal-80w">
                    <Modal.Header closeButton>
                        <Modal.Title className="send-notes-title"> Create New Course</Modal.Title>
                       
                            {/* <Button onClick={() => setCourseModal(false)} className="cancel-profile">
                                Cancel
                            </Button> */}
                            <Button className="save-profile create-new-course" onClick={addCourse}>
                                Create Course
                            </Button>
                     
                    </Modal.Header>
                    <Modal.Body>
                        <TextField className="overview-container"
                            id="outlined-multiline-static"
                            name="name"
                            label="Course Name"
                            variant="standard"
                            value={course.name}
                            onChange={handleCourseChange}
                        />
                        <div className="attach-notes">
                            Course Image
                            <div className="notes-images">
                                <img className="upload-notes" src={course.image || uploadbutton} alt="Profile" onClick={handleImageClick} />
                            </div>

                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <TextField className="overview-container"
                            id="outlined-multiline-static"
                            label="Description"
                            name="description"
                            multiline
                            rows={2}
                            variant="standard"
                            value={course.description}
                            onChange={handleCourseChange}
                        />
                    </Modal.Body>
                </Modal>
            </div>


            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
        </>
    )
}

export default TutorHistory;
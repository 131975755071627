import { React, useState, useEffect, api, API_URL, Button, Modal, PuffLoader, override, ToastContainer, toast } from "../../../services/common";
import { useRef } from "react";
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import marketing from "../../../assets/images/marketing.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import defaultimage from "../../../assets/images/uploadimage.png";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { NavLink } from 'react-router-dom';
import { TextField } from "@mui/material";
import { Card } from "react-bootstrap";
import classcount from "../../../assets/images/class-count.svg";
import tutoricon from "../../../assets/images/tutor.svg"
import defaultprofile from "../../../assets/images/review-image.jpg"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "react-toastify/dist/ReactToastify.css";
import "./StudentDashboard.css";

const StudentDashboard = () => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedSlotId, setSelectedSlotId] = useState(null); // When Selecting for Rescheduling
    const [schedule, setSchedule] = useState([]); // Day wise Schedule   
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        phone: '',
        image: ''
    })
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date())); //For Schedule
    const [profileOptions, setProfileOptions] = useState(false);  // The List that opens on Profile Click
    const [selectedClass, setSelectedClass] = useState(null);
    const [timeDifference, setTimeDifference] = useState('');
    const [classModal, setClassModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    let id = localStorage.getItem('uid');

    const showCancelModal = () => {
        setCancelModal(true);
        setClassModal(false);
    }

    const showClassModal = (class_detail) => {
        setClassModal(true);
        setSelectedClass(class_detail);
        let currentTime = new Date();
        let timeDiff = new Date(class_detail.dateTime) - currentTime;
        setTimeDifference(timeDiff);
    };

    const viewClassDetails = () => {
        let tutor = user.tutors.find(o => o.id === selectedClass.tutor_id && o.course === selectedClass.course);
        if (tutor) {
            tutor.date = selectedDate.$d;
            navigate(`/student-panel/history/${tutor.id}/${tutor.course}`, { state: { tutorData: tutor } });
        }
    };

    const cancelClass = (type) => {
        setLoading(true);
        const body = {
            slotId: selectedClass.id,
            cancel_reason: cancelReason
        };
        if (type) {
            body["cancel_reason"] = "Rescheduling to different slot";
        }

        api.post(API_URL + "/students/cancel-booking/" + id, body).then((response) => {
            setLoading(false);
            setCancelModal(false);
            changeDate(selectedDate);
            toast.success("Class Cancelled Successfully");
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    };

    const showRescheduleModal = () => {
        setLoading(true);
        setRescheduleModal(true);
        setClassModal(false);
        const id = selectedClass.tutor_id;
        api.get(API_URL + "/tutors/view-available-schedule/" + id).then((response) => {
            setLoading(false);
            setAvailableSlots(response.data);
            if (!response.data.length) {
                toast.error("No Slots Available")
            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    }

    const rescheduleClass = () => {
        setLoading(true);
        let tutor_id = selectedClass.tutor_id;
        let body = {
            slotId: selectedSlotId,
            student: id,
            course: selectedClass?.course
        }
        api.post(API_URL + "/tutors/book-schedule/" + tutor_id, body).then((response) => {
            setLoading(false);
            setCancelReason("Rescheduling to different slot");
            cancelClass(1);
            toast.success("Class Rescheduled Successfully");
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    }

    const formatCustomDate = (dateString, timeZone = 'Asia/Kolkata') => {
        const date = new Date(dateString);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        const tomorrow = new Date(now);
        tomorrow.setDate(now.getDate() + 1);
        const options = {
            timeZone, weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };

        if (date.toDateString() === now.toDateString()) {
            return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Yesterday ';
        } else if (date.toDateString() === tomorrow.toDateString()) {
            return 'Tomorrow ';
        } else {
            return date.toLocaleString(undefined, options);
        }
    }

    const changeDate = (date) => {
        setLoading(true);
        setSelectedDate(date);
        const params = {
            date: date
        };
        api.get(API_URL + "/students/view-schedule/" + id, { params }).then((response) => {
            const temp = [...response.data];
            setSchedule(temp);
            setLoading(false);
            response.data.forEach((res) => {
                res.time = new Date(res.date).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
                res.dateTime = res.date;
                res.date = formatCustomDate(res.date);
            }
            )
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    }

    const handleTextClick = () => {
        fileInputRef.current.click();
    };


    const fetchCourseNameFromTutor = async (tutorId, courseId) => {
        try {
            const response = await api.get(API_URL + `/tutors/view-tutor/` + tutorId);
            const currentCourse = response.data?.courses.find(c => c.id === courseId);
            return currentCourse ? currentCourse.name : null;
        } catch (error) {
            console.log('Error fetching course name for tutor:', error);
            return null; // Return null if there's an error
        }
    };

    const handleFileChange = (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        api.post(API_URL + "/common/upload-image", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.image_url) {
                toast.success("Image Uploaded Successfully");
                setProfile(prevProfile => ({
                    ...prevProfile,
                    image: response.data.image_url
                }));
            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message || error.message);
                console.log(error.response.data.message);
            });
    };

    const convertValiditiesToDate = (tutorsArray) => {
        return tutorsArray.map(tutor => {
            if (tutor.validity && tutor.validity._seconds && tutor.validity._nanoseconds) {
                const timestamp = tutor.validity;
                const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6);
                // Replace the validity object with the formatted date
                tutor.validity = date.toLocaleString('en-IN', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
            }

            return tutor;
        });
    }

    const getProfile = async () => {
        try {
            setLoading(true);
            
            // Await the API call to fetch student profile
            const response = await api.get(API_URL + "/students/view-student/" + id);
            
            setLoading(false);
            
            if (response.data.tutors) {
                const updatedTutors = convertValiditiesToDate(response.data.tutors);
                
                // Await all the API calls to fetch course names for tutors
                const updatedTutorsWithCourses = await Promise.all(
                    updatedTutors.map(async (tutor) => {
                        if (tutor.course) {
                            // Fetch course name for each tutor
                            const courseName = await fetchCourseNameFromTutor(tutor.id, tutor.course);
                            tutor.courseName = courseName || 'Unknown Course'; // Assign course name or fallback value
                        }
                        return tutor;
                    })
                );
                
                response.data.tutors = updatedTutorsWithCourses; // Update tutors with course names
            }
            
            // Set the user data in the state
            setUser(response.data);
            
            // Extract and set profile data
            const { name, email, phone, image } = response.data;
            setProfile({ name, email, phone, image });
            
            // Store image in local storage
            localStorage.setItem('image', response.data?.image);
            
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error.message);
        }
    };
    

    const logoutProfile = () => {
        localStorage.clear();
        logout();
        setTimeout(() => navigate("/"), 100); 
    }

    const updateProfile = () => {
        setLoading(true);
        let body = {
            name: profile.name,
            phone: profile.phone && !profile.phone.startsWith("+") ? "+" + profile.phone : profile.phone,   
            image: profile.image
        }
        api.put(API_URL + "/students/update-student/" + id, body).then((response) => {
            setLoading(false);
            getProfile();
            toast.success("Profile Updated")
            setProfileModal(false);
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error.message);
            });
    }

    useEffect(() => {
        changeDate(selectedDate);
        getProfile();
    }, []);


    return (

        <div className="row main-frame main-frame-mobile">
            <div className="col-3 padding-remove mobile">
                <StudentSidebar></StudentSidebar>
            </div>
            <div className="col-6 main-dashboard">
                <div>
                    <div className="user-greeting">
                        <div className="welcome">Welcome, {user.name}</div>
                        <div className="good-day">Have a good day!</div>
                    </div>

                    <img className="marketing-img" src={marketing} alt="Trending on the Platform for Marketing Purpose"></img>
                </div>

                <div>
                    <div className="course-headings">
                        <span className="mycourses">My Courses</span>
                        <NavLink to="/student-panel/history">
                            <span className="view-all">View All</span>
                        </NavLink>
                    </div>
                    <div className="course-container">
                        {user.tutors && user.tutors.map((tutor, index) => (
                            <div key = {index} className="course-list-item">
                                <svg className="course-strip" xmlns="http://www.w3.org/2000/svg" width="290" height="11" viewBox="0 0 290 11" fill="none">
                                    <path d="M0 11C0 4.92487 4.92487 0 11 0H279C285.075 0 290 4.92487 290 11H0Z" fill="#7F56D9" />
                                </svg>
                                <div className="course-label">{tutor.courseName}</div>
                                <p className="course-info">{tutor.description || `Explore the joy of music in our ${tutor.courseName} class. Unleash your musical potential with us!`}</p>
                                <div className="course-icons">
                                    <div className="course-instructor">
                                        <img src={tutoricon} alt="Tutor Icon" />
                                        {tutor.name}</div>

                                    <div className="course-instructor">
                                        <img src={classcount} alt="Tutor Icon" />
                                        {tutor.class_attended} classes</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="col-3 calendar-section mobile">
                <div className="header-panel" >
                    <div className="header-elements">
                        {/* <img className="notifications-icon" src={notifications} alt="Tutor Icon" /> */}
                        <div onClick={() => setProfileOptions(!profileOptions)}>
                            <img className="profile-dropdown" src={user?.image || defaultprofile} alt="Profile" />
                        </div>

                        {profileOptions && (
                            <div className="profile-options">
                                <ul className="header-list">
                                    <li className="list-item">
                                        {user.name}
                                    </li>
                                    <li className="profile-email" >
                                        {user.email}
                                    </li>
                                    <hr></hr>
                                    {/* <li className="list-item"> <svg className="profile-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M19.1401 12.9399C19.1801 12.6399 19.2001 12.3299 19.2001 11.9999C19.2001 11.6799 19.1801 11.3599 19.1301 11.0599L21.1601 9.4799C21.3401 9.3399 21.3901 9.0699 21.2801 8.8699L19.3601 5.5499C19.2401 5.3299 18.9901 5.2599 18.7701 5.3299L16.3801 6.2899C15.8801 5.9099 15.3501 5.5899 14.7601 5.3499L14.4001 2.8099C14.3601 2.5699 14.1601 2.3999 13.9201 2.3999H10.0801C9.84011 2.3999 9.65011 2.5699 9.61011 2.8099L9.25011 5.3499C8.66011 5.5899 8.12011 5.9199 7.63011 6.2899L5.24011 5.3299C5.02011 5.2499 4.77011 5.3299 4.65011 5.5499L2.74011 8.8699C2.62011 9.0799 2.66011 9.3399 2.86011 9.4799L4.89011 11.0599C4.84011 11.3599 4.80011 11.6899 4.80011 11.9999C4.80011 12.3099 4.82011 12.6399 4.87011 12.9399L2.84011 14.5199C2.66011 14.6599 2.61011 14.9299 2.72011 15.1299L4.64011 18.4499C4.76011 18.6699 5.01011 18.7399 5.23011 18.6699L7.62011 17.7099C8.12011 18.0899 8.65011 18.4099 9.24011 18.6499L9.60011 21.1899C9.65011 21.4299 9.84011 21.5999 10.0801 21.5999H13.9201C14.1601 21.5999 14.3601 21.4299 14.3901 21.1899L14.7501 18.6499C15.3401 18.4099 15.8801 18.0899 16.3701 17.7099L18.7601 18.6699C18.9801 18.7499 19.2301 18.6699 19.3501 18.4499L21.2701 15.1299C21.3901 14.9099 21.3401 14.6599 21.1501 14.5199L19.1401 12.9399ZM12.0001 15.5999C10.0201 15.5999 8.40011 13.9799 8.40011 11.9999C8.40011 10.0199 10.0201 8.3999 12.0001 8.3999C13.9801 8.3999 15.6001 10.0199 15.6001 11.9999C15.6001 13.9799 13.9801 15.5999 12.0001 15.5999Z" fill="black" fill-opacity="0.56" />
                                    </svg>Settings</li> */}
                                    <li className="list-item" onClick={() => setProfileModal(true)}><svg className="profile-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="black" fillOpacity="0.56" />
                                    </svg>Profile</li>
                                    <hr></hr>
                                    <li onClick={logoutProfile} className="list-item"><svg className="profile-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="black" fillOpacity="0.56" />
                                    </svg>Logout</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                <div className="calendar-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem >
                                <DateCalendar value={selectedDate} onChange={(newValue) => changeDate(newValue)} />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className = "upcoming-class-section">
                    <span className="upcoming-class">Upcoming Classes</span>
                    <div>
                        {schedule.map((class_details, index) => (
                            <div className="point-cursor" key={index} onClick={() => showClassModal(class_details)} >
                                <div className="class-schedule row">
                                    <div className="col-2 bullet-point">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                            <circle cx="4" cy="4" r="4" fill="#7F56D9" />
                                        </svg>
                                    </div>
                                    <div className="col-8">
                                        <div><span className="course-detail">{class_details?.courseName} | {class_details?.tutorName}</span>
                                            <p className="course-time">{class_details.time}</p></div></div>

                                    <div className="col-2">
                                        <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M6.6825 14.9401L11.5725 10.0501C12.15 9.47256 12.15 8.52756 11.5725 7.95006L6.6825 3.06006" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                        )
                        }
                        {
                            schedule.length === 0 && <div className="class-schedule">
                                <div><span className="course-detail">No Classes Scheduled</span>
                                </div></div>
                        }

                    </div>
                </div>

                <Modal show={rescheduleModal} onHide={() => setRescheduleModal(false)} dialogClassName="modal-25w">
                    <Modal.Header closeButton>
                        <b className="cancel-heading">Available Slots (for this week)</b>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex">
                            {availableSlots.map((slot, index) => (
                                <Card key = {index} className={`slot-card ${slot.id === selectedSlotId ? 'selected-card' : ''}`} style={{ width: '10rem' }} onClick={() => setSelectedSlotId(slot.id)}>
                                    <Card.Body>
                                        <Card.Title className="slot-date">{slot.date}</Card.Title>
                                        <Card.Text>
                                            {slot.id === selectedClass?.id && <p className="current-slot">Your Current Slot</p>}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))
                            }
                        </div>
                        <Button className="join-class" onClick={rescheduleClass} disabled={selectedSlotId === selectedClass?.id}>
                            Reschedule Class
                        </Button>
                    </Modal.Body>
                </Modal>


                <Modal show={cancelModal} onHide={() => setCancelModal(false)} dialogClassName="modal-25w">
                    <Modal.Header closeButton>
                        <b className="cancel-heading">Are you sure you want to cancel the class ?</b>
                    </Modal.Header>
                    <Modal.Body>

                        <TextField id="standard-basic" className="profile-form-fields" label="Cancellation Reason" variant="standard" value={cancelReason} placeholder="Enter cancellation reason" onChange={(e) => setCancelReason(e.target.value)} />
                        <Button className="join-class" onClick={cancelClass} disabled={!cancelReason}>
                            Cancel Class
                        </Button>
                    </Modal.Body>
                </Modal>


                <Modal show={classModal} onHide={() => setClassModal(false)} dialogClassName="modal-25w">
                    <Modal.Header closeButton>
                        <Modal.Title>Class Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span className="course-detail">{selectedClass?.courseName} | {selectedClass?.tutorName}</span>
                            <p className="course-time"> {selectedClass?.date}  | {selectedClass?.time}</p>
                        </div>

                        {
                            timeDifference <= -7200000 && <Button className="join-class" onClick={viewClassDetails}>
                                View Class Details
                            </Button>
                        }

                        {
                            timeDifference >= -7200000 && timeDifference <= 7200000 && selectedClass?.link && <Button className="join-class" href={selectedClass?.link} target="_blank">
                                Join Class
                            </Button>
                        }

                        {timeDifference > 7200000 &&
                            <div className="flex">
                                <Button className="join-class" onClick={showRescheduleModal}>
                                    Reschedule Class
                                </Button>
                                <Button className="cancel-class" onClick={showCancelModal}>
                                    Cancel Class
                                </Button>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
                <Modal show={profileModal} onHide={() => setProfileModal(false)} dialogClassName="modal-90w">
                    <Modal.Header closeButton>

                        <Modal.Title>Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-5">
                                <img className="uploadimage" src={profile.image || defaultimage} alt="Profile" />
                                <div className="change-photo" onClick={handleTextClick}>
                                    {profile.image ? 'Change Profile Picture' : 'Upload Profile Picture'}
                                </div>

                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="col-7 student-action-buttons">
                                <TextField id="standard-basic" className="profile-form-fields" label="Name" variant="standard" value={profile.name} onChange={(e) => setProfile(prevProfile => ({ ...prevProfile, name: e.target.value }))} readOnly={false} />
                                <TextField id="standard-basic" className="profile-form-fields" label="Email" variant="standard" value={profile.email} onChange={(e) => setProfile(prevProfile => ({ ...prevProfile, email: e.target.value }))} disabled />
                                <PhoneInput id="standard-basic" className="profile-form-fields" country={'in'} value={profile.phone} onChange={(e) => setProfile(prevProfile => ({ ...prevProfile, phone: e }))}  placeholder="Add phone for class updates"  />
                                <div className="action-buttons">
                                    <Button onClick={() => setProfileModal(false)} className="cancel-profile">
                                        Cancel
                                    </Button>

                                    <Button className="save-profile" onClick={updateProfile}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
        </div>
    )
}

export default StudentDashboard;
